import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { Link } from "gatsby"
import * as fetch from "node-fetch"
import { StaticImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"
import {
  ExclamationCircle,
  XCircle,
  CheckCircle,
  StarIcon,
} from "@heroicons/react/solid"
import SbEditable from "storyblok-react"
import useStoryblok from "../utils/storyblok"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Footer from "../components/Footer"

const schema = yup.object().shape({
  name: yup.string().required().min(3).max(100),
  email: yup
    .string()
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "please enter a valid email",
      { excludeEmptyString: true }
    )
    .required()
    .min(3)
    .max(100),
  terms: yup.bool().oneOf([true]),
})

async function post_request(url, data) {
  console.log("sadasda", data)
  let theUrl = `${url}/comp`
  return fetch(theUrl, {
    method: "post",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then(response => {
      if (response.ok) {
        return "true"
      } else {
        throw "There is something wrong"
      }
    })
    .catch(error => {
      return "false"
    })
}

const Competitions = ({ data, location }) => {
  const [isSuccessful, setIsSuccessful] = useState("")
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  })

  const onSubmit = async data => {
    setIsSuccessful(undefined)
    const today = new Date()
    data.date = today.toISOString()
    data.subject = "competitionform"

    const apiURl =
      process.env.APIURL === undefined
        ? process.env.LOCALURL
        : process.env.APIURL

    const result = await post_request(apiURl, data)

    setIsSuccessful(result)
    if (result === "true") {
      reset("", {
        keepValues: false,
      })
    }

    return result
  }

  return (
    <Layout>
      <Seo title="Contact Shopsmart" pageName="competitions" />
      <h1 class="sr-only">
        Welcome to Shopsmart. This is our competitions page.
      </h1>
      <form
        className="space-y-8 mr-8 ml-8 pt-16"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
          <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
            <div>
              <h2 className="text-3xl font-normal tracking-tight text-red-default mb-12">
                Congrats to Odete Anderson for winning the Michael Hill gift
                card!
                <span role="img" aria-label="party">
                  {" "}
                  &#127881; &#127881; &#127881; &#127881; &#127881; &#127881;
                </span>
              </h2>
              <h2 className="text-3xl font-normal tracking-tight text-red-default">
                Win a Michael Hill gift card!
              </h2>
              <p className="mt-1 max-w-2xl text-gray-500 mb-2">
                Like Jewellery? What about a deal? Shopsmart is running a
                competiton to get more interest in our app.
              </p>
              <p className="mt-1 max-w-2xl text-gray-500 mb-2">
                The winner will receive a $100{" "}
                <a
                  href="https://www.michaelhill.com.au/"
                  className="flex-1"
                  title="Shopsmart app"
                >
                  Michael Hill
                </a>{" "}
                gift card. You can check their{" "}
                <a
                  href="https://www.michaelhill.com.au/"
                  className="flex-1"
                  title="Shopsmart app"
                >
                  website here
                </a>
                .
              </p>
              <p className="mt-1 max-w-2xl text-gray-500 mb-2">
                The competiton will drawn on the{" "}
                <strong>10th of September 2021</strong>.
              </p>
              <p className="mt-1 max-w-2xl text-gray-500 mb-2">
                It's simple. To enter just do the following:
              </p>
              <ol>
                <li>
                  <span className="flex-1">
                    Download{" "}
                    <a
                      href="https://www.shopsmart.com.au/app"
                      className="flex-1"
                      title="Shopsmart app"
                    >
                      our app
                    </a>{" "}
                    and leave a
                    <StarIcon className="h-5 w-5 text-yellow-500 inline" />
                    <StarIcon className="h-5 w-5 text-yellow-500 inline" />
                    <StarIcon className="h-5 w-5 text-yellow-500 inline" />
                    <StarIcon className="h-5 w-5 text-yellow-500 inline" />
                    <StarIcon className="h-5 w-5 text-yellow-500 inline" /> star
                    review.
                  </span>
                </li>
                <li>
                  Follow us on{" "}
                  <a
                    href="https://www.facebook.com/shopsmartai"
                    title="Shopsmart on twitter"
                  >
                    Twitter{" "}
                    <svg
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      className="h-5 w-5 text-blue-500 inline"
                    >
                      <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                    </svg>
                  </a>
                </li>
                <li>
                  Follow us on{" "}
                  <a
                    href="https://www.instagram.com/shopsmartai"
                    title="Shopsmart on insta"
                  >
                    Instagram
                    <svg
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      className="h-5 w-5 text-purple-700 inline"
                    >
                      <path
                        fillRule="evenodd"
                        d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </a>
                </li>
              </ol>
              <p className="mt-1 max-w-2xl text-gray-500 mb-2">
                Then enter your name and email below.
              </p>
            </div>
            <div className="space-y-6 sm:space-y-5">
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Name: <span className="text-xs">(required)</span>
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    className="max-w-lg block w-full shadow-sm focus:ring-gray-500 focus:border-gray-500 sm:max-w-xs sm:text-sm border-2 border-gray-300 rounded-md p-2"
                    {...register("name", {
                      required: true,
                      maxLength: 100,
                      minLength: 3,
                    })}
                  />

                  <p class="mt-2 text-md text-red-600" id="name-error">
                    <div class="inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                      {errors.name?.message.length > 0 ? (
                        <svg
                          class="h-9 w-9 text-red-500 p-1"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                            clipRule="evenodd"
                          />
                        </svg>
                      ) : (
                        <p></p>
                      )}

                      {errors.name?.message}
                    </div>
                  </p>
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Email address: <span className="text-xs">(required)</span>
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    className="max-w-lg block w-full shadow-sm focus:ring-gray-500 focus:border-gray-500 sm:max-w-xs sm:text-sm border-2 border-gray-300 rounded-md p-2"
                    {...register("email", {
                      required: true,
                      maxLength: 100,
                      minLength: 3,
                      message: "hi",
                    })}
                  />
                  <p class="mt-2 text-md text-red-600" id="email-error">
                    <div class="inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                      {errors.email?.message.length > 0 ? (
                        <svg
                          class="h-9 w-9 text-red-500 p-1"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                            clipRule="evenodd"
                          />
                        </svg>
                      ) : (
                        <p></p>
                      )}

                      {errors.email?.message}
                    </div>
                  </p>
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  Competition Terms:
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <div className="rounded-t-xl overflow-hidden bg-green-100 p-4 grid  justify-items-end">
                    <p>Scroll to read </p>
                    <svg
                      class="animate-bounce w-6 h-6"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path d="M19 14l-7 7m0 0l-7-7m7 7V3"></path>
                    </svg>

                    <div className="overflow-scroll overflow-y-scroll leading-relaxed text-gray-800 text-sm h-48 bg-green-200 p-2">
                      <h5 className="text-gray-800">TERMS AND CONDITIONS</h5>
                      <ol class="list-decimal list-inside">
                        <li>
                          The Promoter is: <strong>Shopsmart</strong>
                        </li>
                        <li>
                          The abovementioned company will be hereafter be
                          referred to as the 'Promoter'
                        </li>
                        <li>
                          Employees of the Promoter or their family members or
                          anyone else connected in any way with the competition
                          or helping to set up the competition shall not be
                          permitted to enter the competition
                        </li>
                        <li>
                          To enter the competition entrants must:
                          <ul className="ml-2 list-disc list-inside">
                            <li>Download our app and leave a 5 star review</li>
                            <li>Follow us on Twitter</li>
                            <li>Follow us on Instagram</li>
                          </ul>
                        </li>
                        <li>
                          No responsibility can be accepted for entries not
                          received for whatever reason
                        </li>
                        <li>
                          The rules of the competition and the prize for each
                          winner are as follows:{" "}
                          <ul className="ml-2 list-disc list-inside">
                            <li>The entrant must complete the task above</li>
                            <li>
                              The entrant must be over the age of 18 years
                            </li>
                            <li>The entrant must be living Australia</li>
                          </ul>
                        </li>
                        <li>
                          The Promoter reserves the right to cancel or amend the
                          competition and these terms and conditions without
                          notice in the event of a catastrophe, war, civil or
                          military disturbance, act of God or any actual or
                          anticipated breach of any applicable law or regulation
                          or any other event outside of the Promoter's control.
                          Any changes to the competition will be notified to
                          entrants as soon as possible by the Promoter
                        </li>
                        <li>
                          The Promoter is not responsible for inaccurate prize
                          details supplied to any entrant by any third party
                          connected with this competition
                        </li>
                        <li>
                          No cash alternative to the prizes will be offered. The
                          prizes are not transferable. Prizes are subject to
                          availability and we reserve the right to substitute
                          any prize with another of equivalent value without
                          giving notice
                        </li>
                        <li>
                          The prize will be drawn on the 10th of September 2021.
                          The winner will be announced on the website
                        </li>
                        <li>
                          The winner will be notified by email within 28 days of
                          the closing date. If the winner cannot be contacted or
                          do not claim the prize within 14 days of notification,
                          we reserve the right to withdraw the prize from the
                          winner and pick a replacement winner
                        </li>
                        <li>
                          The Promoter will notify the winner and send the prize
                          electronically
                        </li>
                        <li>
                          The Promoter’s decision in respect of all matters to
                          do with the competition will be final and no
                          correspondence will be entered into
                        </li>
                        <li>
                          The winner agrees to the use of his/her name Any
                          personal data relating to the winner or any other
                          entrants will be used solely in accordance with
                          current{" "}
                          <a
                            href="https://www.oaic.gov.au/privacy/the-privacy-act/"
                            target="_new"
                          >
                            Australian data protection legislation
                          </a>
                          and will not be disclosed to a third party without the
                          entrant’s prior consent.
                        </li>
                        <li>
                          The winner’s name will be published on our website.
                        </li>
                        <li>
                          This competition is in no way sponsored, endorsed or
                          administered by, or associated with, any company or
                          Social Network. You are providing your information to
                          the Promoter and not to any other party
                        </li>
                      </ol>
                      <h6 className="text-gray-800">Lastly, Good Luck!</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
            <div class="flex items-start">
              <div class="flex items-center h-5">
                <input
                  id="futureContact"
                  name="futureContact"
                  type="checkbox"
                  class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                  {...register("futureContact", {
                    required: false,
                  })}
                />
              </div>
              <div class="ml-3 text-sm">
                <label for="futureContact" class="font-medium text-gray-700">
                  Would you like us to contact you for future competitions or
                  special offers? <span className="text-xs">(optional)</span>
                </label>
              </div>
            </div>
          </div>
          <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
            <div class="flex items-start">
              <div class="flex items-center h-5">
                <input
                  id="terms"
                  name="terms"
                  type="checkbox"
                  class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                  {...register("terms", {
                    required: true,
                  })}
                />
              </div>
              <div class="ml-3 text-sm">
                <label for="terms" class="font-medium text-gray-700">
                  Terms and conditions
                </label>
                <p class="text-gray-500">
                  By ticking the box above you are confirming that you have read
                  and agree to our{" "}
                  <a
                    href="https://www.shopsmart.com.au/privacy-policy"
                    target="_blank"
                  >
                    privacy policy
                  </a>{" "}
                  and that you agree to the terms and conditions of the
                  competition .
                </p>
                <p class="mt-2 text-md text-red-600" id="terms-error">
                  <div class="inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    {errors.terms?.message.length > 0 ? (
                      <svg
                        class="h-9 w-9 text-red-500 p-1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                          clipRule="evenodd"
                        />
                      </svg>
                    ) : (
                      <p></p>
                    )}
                    {errors.terms?.message.length > 0 ? (
                      <p>
                        You need to agree to the terms before you submuit this
                        form.{" "}
                      </p>
                    ) : (
                      <p></p>
                    )}
                  </div>
                </p>
              </div>
            </div>
          </div>
        </div>
        {isSuccessful === "false" ? (
          <div class="rounded-md bg-red-50 p-4">
            <div class="flex">
              <div class="flex-shrink-0">
                <svg
                  class="h-5 w-5 text-red-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <div class="ml-3">
                <h3 class="text-sm font-medium text-red-800">
                  An internal error occurred with your submission. Please try
                  again later.
                </h3>
              </div>
            </div>
          </div>
        ) : (
          <p> </p>
        )}

        {isSuccessful === "true" ? (
          <div class="rounded-md bg-green-50 p-4">
            <div class="flex">
              <div class="flex-shrink-0">
                <svg
                  class="h-5 w-5 text-green-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <div class="ml-3">
                <h3 class="text-sm font-medium text-green-800">Success!</h3>
                <div class="mt-2 text-sm text-green-700">
                  <p>Good luck</p>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <p> </p>
        )}

        <div className="pt-5 pb-5">
          <div className="flex justify-end">
            {/* <button
              disabled="disabled"
              type="submit"
              className="w-32 ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              Send
              {isSuccessful === undefined ? (
                <svg
                  class="animate-spin ml-2 mr-3 h-5 w-7 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    class="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    class="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : (
                <p> </p>
              )}
            </button> */}
          </div>
        </div>
      </form>
      <Footer />
    </Layout>
  )
}

export default Competitions
